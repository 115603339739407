import React, { useState, useEffect, useRef } from "react";
import { connect, useSelector } from "react-redux";
import * as builder from "../../ducks/builder";
// import Brand from "../brand/Brand";
import PerfectScrollbar from "react-perfect-scrollbar";
import Menu from "./Menu";
import KTOffcanvas from "../../_assets/js/offcanvas";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import * as MenuConfig from '../MenuConfig'
import MenuItemMin from './MenuItemMin'
import '../Layout.scss'
import { Link } from 'react-router-dom';
import logoutIcon from '../../_assets/media/svg-icon/logout.svg'

const AsideLeft = props => {
  const asideOffCanvasRef = useRef();
  const [ktoffConvas, setKtoffConvas] = useState()
  const role = useSelector((store) => store.auth.user.roles);

  useEffect(() => {
    window.addEventListener('resize', handleResize)
    setKtoffConvas(new KTOffcanvas(
      asideOffCanvasRef.current,
      props.menuCanvasOptions
    ))
  }, []);

  const handleResize = () => {
    if (window.innerWidth < 1025) {
      props.setVisibleMin(false)
    }
  };

  const menuName = MenuConfig.getName(props.location, props.auth.user.roles);
  const menuConfig = MenuConfig[menuName];

  return (
    <>
      <button className="kt-aside-close" id="kt_aside_close_btn">
        <i className="la la-close" />
      </button>

      {props.visibleMin
        ? (
          <div
            id="kt_aside"
            ref={asideOffCanvasRef}
            className={`kt-aside kt-aside__min ${props.asideClassesFromConfig} kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop${!props.visibleMin ? ' kt_aside-min' : ''}`}
          >
            {/* <Brand /> */}
            <div className="hide-btn" onClick={() => {
              props.setVisibleMin(!props.visibleMin)
            }}>
              <ArrowForwardIosIcon />
            </div>
            <ul className="kt-menu__nav">
              {MenuConfig[MenuConfig.getName(props.location, props.auth.user.roles)].aside.items.map((item, index) => {
                return (
                  <MenuItemMin
                    location={props.location}
                    item={item}
                    key={index}
                  />
                )
              })}
            </ul>
          </div>
        )
        : (
          <div
            id="kt_aside"
            ref={asideOffCanvasRef}
            className={`kt-aside ${props.asideClassesFromConfig} kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop${props.visibleMin ? ' kt_aside-big' : ''}`}
          >
            {/* <Brand /> */}
            <div
              id="kt_aside_menu_wrapper"
              className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid"
            >
              {props.disableScroll && <Menu key={window.location.pathname} htmlClassService={props.htmlClassService} ktoffConvas={ktoffConvas} localMenuConfig={menuConfig} />}
              {!props.disableScroll && (
                <PerfectScrollbar
                  options={{ wheelSpeed: 2, wheelPropagation: false }}
                >
                  <Menu key={window.location.pathname} htmlClassService={props.htmlClassService} ktoffConvas={ktoffConvas} localMenuConfig={menuConfig} />
                </PerfectScrollbar>
              )}
            </div>
            <div
              className="hide-btn"
              onClick={() => {
                props.setVisibleMin(!props.visibleMin)
              }}
            >
              <ArrowBackIosIcon />
              Hide Menu
            </div>
            {role === 'client' && 
            (<div className="aside-left-logout__wrap">
              <Link className="aside-left-logout" to="/logout">
                <img src={logoutIcon} alt="logout" /> 
                Logout
              </Link>
            </div>)
            }
          </div>
        )
      }
    </>
  );
}

const mapStateToProps = store => ({
  auth: store.auth,
  disableAsideSelfDisplay:
    builder.selectors.getConfig(store, "aside.self.display") === false,
  disableScroll:
    builder.selectors.getConfig(store, "aside.menu.dropdown") === "true" ||
    false,
  asideClassesFromConfig: builder.selectors.getClasses(store, {
    path: "aside",
    toString: true
  }),
  menuCanvasOptions: {
    baseClass: "kt-aside",
    overlay: true,
    closeBy: "kt_aside_close_btn",
    toggleBy: {
      target: "kt_aside_mobile_toggler",
      state: "kt-header-mobile__toolbar-toggler--active"
    }
  }
});

export default connect(mapStateToProps)(AsideLeft);
