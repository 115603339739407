import * as utils from "../../_metronic/utils/utils";

const localStorageLastLocationKey = "demo1-lastLocation";

function acceptLocation(lastLocation) {
  if (
    lastLocation &&
    lastLocation.pathname &&
    lastLocation.pathname !== "/" &&
    lastLocation.pathname.indexOf("auth") === -1 &&
    lastLocation.pathname !== "/logout"
  ) {
    return true;
  }

  return false;
}

export function saveLastLocation(lastLocation) {
  if (!lastLocation) return;
  
  const localStorateLocations = utils.getStorage(localStorageLastLocationKey);
  let _useLocations = localStorateLocations
    ? JSON.parse(localStorateLocations)
    : [];

  if (acceptLocation(lastLocation)) {
    // Only add if it's different from the last location
    if (_useLocations[_useLocations.length - 1] !== lastLocation.pathname) {
      _useLocations.push(lastLocation.pathname);
      utils.setStorage(
        localStorageLastLocationKey,
        JSON.stringify(_useLocations),
        120
      );
    }
  }
}

export function forgotLastLocation() {
  utils.removeStorage(localStorageLastLocationKey);
}

export function getLastLocation() {
  const localStorateLocations = utils.getStorage(localStorageLastLocationKey);
  if (!localStorateLocations) {
    return "/";
  }

  const _userLocations = JSON.parse(localStorateLocations);
  const result = _userLocations.length > 0 ? _userLocations[_userLocations.length - 1] : "/";
  return result;
}

export function getPreviousLocation() {
  const localStorateLocations = utils.getStorage(localStorageLastLocationKey);
  if (!localStorateLocations) {
    return "/";
  }

  const _userLocations = JSON.parse(localStorateLocations);
  if (_userLocations.length <= 1) {
    return "/";
  }
  
  // Return the second to last location
  return _userLocations[_userLocations.length - 2];
}
