import React, { useEffect, useState, Suspense } from "react";
import { Redirect, Route, Switch, withRouter , useLocation} from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import { getUserByToken, getAccountByToken } from "./app/crud/auth.crud";
import { LayoutSplashScreen } from "./_metronic";
import { useDispatch } from "react-redux";
import * as AlertState from "./app/store/ducks/auth.duck";
import Intercom from "intercom-react";
import Drift from "react-driftjs";
import Profitwell from "profitwell-component";
import { fetchCRMList } from "./app/crud/crm.crud";
import { setCRMAction, setPlanAction } from "./app/store/actions";
import { LayoutContextProvider } from "./_metronic";
import Layout from "./_metronic/layout/Layout";
import lazyWithRetry from "./app/helpers/lazyWithRetry";
import { getCurrentSubscription } from "./app/crud/biling.crud";
import * as routerHelpers from "./app/router/RouterHelpers";
import { setAccountsList } from "./app/store/reducers/accountsSlice";
import { setCrmList } from "./app/store/reducers/crmSlice";
import { setSubscriptionList } from "./app/store/reducers/subscriptionSlice";
import { getAllUsers } from "./app/crud/info.crud";
import { getUsersV3 } from "./app/crud/info.crud";
import { getAllGroup } from "./app/crud/info.crud";
import { setUsersList } from "./app/store/reducers/usersSlice";
import { setGroupsList } from "./app/store/reducers/groupsSlice";
import { ProfileRoutes } from "./app/router/ProfileRoutes";
import { setAccount } from "./app/store/actions";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import { LayoutLoader } from "./_metronic/layout/loader/Loader";
import { actions } from "./_metronic/ducks/builder";
import { fetchUsersThunk } from "./app/store/reducers/usersSlice";

const FormPDF = lazyWithRetry(() => import("./app/pages/PDF/FormPDF"));
const ExercisePdf = lazyWithRetry(() => import("./app/pages/PDF/ExercisePdf"));
const EventPdf = lazyWithRetry(() => import("./app/pages/PDF/EventPdf"));
const WorkoutPdf = lazyWithRetry(() => import("./app/pages/PDF/WorkoutPdf"));

const ProfileWorkoutPdf = lazyWithRetry(() =>
  import("./app/pages/PDF/ProfileWorkoutPdf")
);

const ProgramPdf = lazyWithRetry(() => import("./app/pages/PDF/ProgramPdf"));

const AuthPage = lazyWithRetry(() => import("./app/pages/auth/AuthPage"));
const ReportPDF = lazyWithRetry(() => import("./app/pages/PDF/ReportPDF"));
const RefreshPassword = lazyWithRetry(() =>
  import("./app/pages/auth/RefreshPassword")
);
const LogoutPage = lazyWithRetry(() => import("./app/pages/auth/Logout"));
const ErrorsPage = lazyWithRetry(() => import("./app/pages/errors/ErrorsPage"));

const ProfileList = lazyWithRetry(() => import("./app/pages/home/ProfileList"));

const ProfileCreate = lazyWithRetry(() =>
  import("./app/pages/home/ProfileCreate")
);

const AccountInfo = lazyWithRetry(() => import("./app/pages/home/AccountInfo"));

const Metrics = lazyWithRetry(() => import("./app/pages/home/Metrics"));

const Overview = lazyWithRetry(() => import("./app/pages/home/Overview"));

const ProfileInfo = lazyWithRetry(() => import("./app/pages/home/ProfileInfo"));

const AccountSetting = lazyWithRetry(() => import("./app/pages/home/Setting"));

const Goals = lazyWithRetry(() => import("./app/pages/home/Goals"));

const Program = lazyWithRetry(() => import("./app/pages/home/Program"));
const Testing = lazyWithRetry(() =>
  import("./app/pages/home/TestingTabs/Testing")
);
const Report = lazyWithRetry(() => import("./app/pages/home/Report"));
const UserList = lazyWithRetry(() => import("./app/pages/home/User/UserList"));
const UserCreate = lazyWithRetry(() =>
  import("./app/pages/home/User/UserCreate")
);
const GroupList = lazyWithRetry(() =>
  import("./app/pages/home/Group/GroupList")
);
const GroupCreate = lazyWithRetry(() =>
  import("./app/pages/home/Group/GroupCreate")
);

const MetricsList = lazyWithRetry(() => import("./app/pages/home/MetricsList"));
const GoalsList = lazyWithRetry(() => import("./app/pages/home/GoalsList"));
const Biling = lazyWithRetry(() => import("./app/pages/home/UpgradePlan"));

const Cancel = lazyWithRetry(() => import("./app/pages/home/Cancel"));
const СRMConnect = lazyWithRetry(() => import("./app/pages/home/CRM/Connect"));
const СRMGroup = lazyWithRetry(() => import("./app/pages/home/CRM/Group"));
const CRMUser = lazyWithRetry(() => import("./app/pages/home/CRM/User"));
const CRMEvent = lazyWithRetry(() => import("./app/pages/home/CRM/Event"));
const CRMProfile = lazyWithRetry(() => import("./app/pages/home/CRM/Profile"));
const Files = lazyWithRetry(() => import("./app/pages/home/Files"));
const Notes = lazyWithRetry(() => import("./app/pages/home/Notes/Notes"));

const ProfileTasks = lazyWithRetry(() =>
  import("./app/pages/home/ProfileTasks")
);
const ProfileCalendar = lazyWithRetry(() =>
  import("./app/pages/home/ProfileCalendar")
);

const ReportsList = lazyWithRetry(() =>
  import("./app/pages/home/Analytics/ReportsList/ReportsList")
);

const CreateReportLoader = lazyWithRetry(() =>
  import("./app/pages/home/Analytics/CreateReport/CreateReportLoader")
);

const SessionList = lazyWithRetry(() =>
  import("./app/pages/home/Sales/Session")
);

const LeadsList = lazyWithRetry(() => import("./app/pages/home/Sales/Leads"));

const OpportunitiesList = lazyWithRetry(() =>
  import("./app/pages/home/Sales/Opportunities")
);
const ForecastsList = lazyWithRetry(() =>
  import("./app/pages/home/Sales/Forecasts")
);

const PerformanceList = lazyWithRetry(() =>
  import("./app/pages/home/Sales/Performance")
);

const FormsList = lazyWithRetry(() =>
  import("./app/pages/home/Forms/FormsList/FormsList")
);

const CreateForm = lazyWithRetry(() =>
  import("./app/pages/home/Forms/CreateForm/CreateForm")
);

const Dashboard = lazyWithRetry(() =>
  import("./app/pages/home/Dashboard/Dashboard")
);
const TaskList = lazyWithRetry(() => import("./app/pages/home/Tasks/TaskList"));
const Calendar = lazyWithRetry(() =>
  import("./app/pages/home/Calendar/Calendar")
);
const ProgramsList = lazyWithRetry(() =>
  import("./app/pages/home/Programs/ProgramsList/ProgramsList")
);
const WorkoutsList = lazyWithRetry(() =>
  import("./app/pages/home/Workouts/WorkoutsList/WorkoutsList")
);
const ExercisesList = lazyWithRetry(() =>
  import("./app/pages/home/Exercises/ExercisesList/ExercisesList")
);
const CreateEditProgram = lazyWithRetry(() =>
  import("./app/pages/home/Programs/CreateEditProgram/CreateEditProgram")
);
const CreateEditWorkout = lazyWithRetry(() =>
  import("./app/pages/home/Workouts/CreateEditWorkout/CreateEditWorkout")
);

const CreateExercise = lazyWithRetry(() =>
  import("./app/pages/home/Exercises/CreateExercise/CreateExercise")
);

const EditCreateWorkout = lazyWithRetry(() =>
  import("./app/pages/home/ProgramTabs/EditCreateWorkout/EditCreateWorkout")
);

const ProfileSales = lazyWithRetry(() =>
  import("./app/pages/home/Sales/ProfileSales/ProfileSales")
);

const Login = lazyWithRetry(() => import("./app/pages/auth/Login"));

const Registration = lazyWithRetry(() =>
  import("./app/pages/auth/Registration")
);

const RegistrationTrial = lazyWithRetry(() =>
  import("./app/pages/auth/RegistrationTrial")
);

const ForgotPassword = lazyWithRetry(() =>
  import("./app/pages/auth/ForgotPassword")
);

const AppBuilder = withRouter((props) => {
  const { pathname } = useLocation();
  const { history } = props;
  const dispatch = useDispatch();
  const accountId = useSelector((state) => state.appData?.account?.id);
  const lastLocation = useLastLocation();
  routerHelpers.saveLastLocation(lastLocation);
  const [intercom, setIntercom] = useState(false);
  const [drift, setDrift] = useState(false);
  const [userId, setUserId] = useState();

  // Handle browser back/forward navigation
  useEffect(() => {
    const handlePopState = () => {
      const previousLocation = routerHelpers.getPreviousLocation();
      if (previousLocation && previousLocation !== pathname) {
        history.push(previousLocation);
      }
    };

    window.addEventListener('popstate', handlePopState);
    return () => window.removeEventListener('popstate', handlePopState);
  }, [pathname, history]);

  // eslint-disable-next-line
  const { isAuthorized, userEmail, menuConfig, userLastLocation } = useSelector(
    ({ auth, urls, builder: { menuConfig } }) => {
      return {
        menuConfig,
        isAuthorized: auth.user != null,
        userEmail: auth.user?.email,
        userLastLocation: routerHelpers.getLastLocation(),
      };
    },
    shallowEqual
  );

  useEffect(() => {
    getAccountByToken().then((res) => {
      setUserId(res.data.data.service_customer_id);
      dispatch(setAccountsList(res.data.data));
      dispatch(setAccount(res.data.data));
    });
    getUserByToken()
      .then((res) => {
        dispatch(AlertState.actions.fulfillUser(res.data));
        setIntercom({
          email: res.data.data.email,
          created_at: res.data.data.emailVerifiedAt,
          name: `${res.data.data.firstName} ${res.data.data.lastName}`,
        });
        setDrift({
          email: res.data.data.email,
          name: `${res.data.data.firstName} ${res.data.data.lastName}`,
          first_name: res.data.data.firstName,
          last_name: res.data.data.lastName,
          company: res.data.data.company_name,
        });
        getCurrentSubscription()
          .then((res2) => {
            if (res2.data.data && res2.data.data.plan) {
              window.Appcues.identify(res.data.data.id, {
                name: `${res.data.data.firstName} ${res.data.data.lastName}`,
                email: res.data.data.email,
                created_at: res.data.data.emailVerifiedAt,
                company_name: res.data.data.company_name,
                plan: res2.data.data.plan.name,
                plan_tier: res2.data.data.plan.id,
                role: res.data.data.roles,
              });
            }
            dispatch(setPlanAction(res2.data.data));
            dispatch(setSubscriptionList(res2.data.data));
            return res2;
          })
          .catch(() => {
            setDrift(false);
            setIntercom(false);
          });
      })
      .catch(() => {
        setDrift(false);
        setIntercom(false);
      });

    // Appcues
    if (window.Appcues) window.Appcues.page();
    // End Appcues
  }, []); // eslint-disable-line

  useEffect(() => {
    if (accountId) {
      fetchCRMList(accountId).then((res) => {
        if (res?.data?.list) {
          dispatch(setCRMAction(res.data.list));
          dispatch(setCrmList(res.data.list));
        }
      });
    }
  }, [accountId]);

  useEffect(() => {
    const fetchUsers = async () => {
      dispatch(fetchUsersThunk());
      // try {
      //   const res = await getUsersV3();
      //   dispatch(setUsersList(res.data.list));
      // } catch (error) {
      //   console.error(error);
      // }
    };
    if (isAuthorized) fetchUsers();
  }, [isAuthorized]);

  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const res = await getAllGroup();
        dispatch(setGroupsList(res.data.data));
      } catch (error) {
        console.error(error);
      }
    };

    if (isAuthorized) fetchGroups();
  }, [isAuthorized]);

  setTimeout(() => {
    dispatch(actions.setLoaderScreen(false));
  }, 3000);

  const renderUserRoutes = () => {
    return (
      <>
      {pathname.includes('/pdf') ?
      <>
        <Route
            path="/profile/:profileId/workout/:id/pdf"
            component={ProfileWorkoutPdf}
          />
        <Route path="/report/:id/:type/:data/pdf" component={ReportPDF} />
        <Route path="/form/:id/pdf" component={FormPDF} />
        <Route path="/exercise/:id/pdf" component={ExercisePdf} />
        <Route path="/events/:id/pdf" component={EventPdf} />
        <Route path="/workout/:id/pdf" component={WorkoutPdf} />
        <Route path="/program/:id/pdf" component={ProgramPdf} />
      </> :
      <Layout {...props}>
        {/* <Suspense fallback={<LayoutSplashScreen />}> */}
        <Suspense fallback={null}>
          <Switch>
            <Redirect exact from="/" to="/profile-list" />

            <Route path="/profile/:id" component={ProfileRoutes} />

            <Route path="/dashboard/main" component={Dashboard} />
            <Route path="/dashboard/tasks" component={TaskList} />
            <Route path="/dashboard/calendar" component={Calendar} />

            <Route path="/account/info" component={AccountInfo} />
            <Route path="/account/setting" component={AccountSetting} />
            <Route path="/account/biling" component={Biling} />
            <Route path="/account/cancel" component={Cancel} />

            <Route path="/account/crm/connect" component={СRMConnect} />
            <Route path="/account/crm/group" component={СRMGroup} />
            <Route path="/account/crm/user" component={CRMUser} />
            <Route path="/account/crm/profile" component={CRMProfile} />
            <Route path="/account/crm/event" component={CRMEvent} />

            <Route path="/library/programs/list" component={ProgramsList} />
            <Route
              path="/library/programs/create"
              exact
              component={CreateEditProgram}
            />
            <Route path="/library/programs/:id" component={CreateEditProgram} />

            <Route path="/library/workouts/list" component={WorkoutsList} />
            <Route
              path="/library/workouts/create"
              exact
              component={CreateEditWorkout}
            />
            <Route path="/library/workouts/:workoutId" component={CreateEditWorkout} />

            <Route path="/library/exercises/list" component={ExercisesList} />
            <Route
              path="/library/exercises/create"
              exact
              component={CreateExercise}
            />

            <Route path="/library/forms/list" component={FormsList} />
            <Route path="/library/forms/create" exact component={CreateForm} />
            <Route path="/library/forms/:id" component={CreateForm} />

            <Route path="/profile-list" component={ProfileList} />
            <Route path="/profile-create" component={ProfileCreate} />

            <Route path="/user-list" component={UserList} />
            <Route path="/user-create" component={UserCreate} />

            <Redirect exact from="/analytics" to="/analytics/reports-list" />
            <Route path="/analytics/reports-list" component={ReportsList} />
            <Route path="/analytics/new_report/:id" component={CreateReportLoader} />
            <Route path="/analytics/report/:id" component={CreateReportLoader} />

            <Route path="/group-list" component={GroupList} />
            <Route path="/group-create" component={GroupCreate} />

            {/* <Route path="/profile/:id/info" component={ProfileInfo} />

            <Route path="/profile/:id/metrics" component={Metrics} />
            <Route path="/profile/:id/overview" component={Overview} />
            <Route path="/profile/:id/goals" component={Goals} />
            <Route path="/profile/:id/program" component={Program} />
            <Route path="/profile/:id/notes" component={Notes} />
            <Route path="/profile/:id/tasks" component={ProfileTasks} />
            <Route path="/profile/:id/calendar" component={ProfileCalendar} />
            <Route path="/profile/:id/testing" component={Testing} />
            <Route path="/profile/:id/report" component={Report} />
            <Route path="/profile/:id/Files" component={Files} />
            <Route path="/profile/:id/sales" component={ProfileSales} />
            <Route
              path="/profile/:profileId/workouts/create"
              component={EditCreateWorkout}
            />
            <Route
              path="/profile/:profileId/workouts/:id"
              component={EditCreateWorkout}
            />

            <Route path="/profile/:id/metrics-list" component={MetricsList} />
            <Route path="/profile/:id/goals-list" component={GoalsList} /> */}

            <Route path="/sales/session" component={SessionList} />
            <Route path="/sales/leads" component={LeadsList} />
            <Route path="/sales/opportunities" component={OpportunitiesList} />
            <Route path="/sales/forecasts" component={ForecastsList} />
            <Route path="/sales/performance" component={PerformanceList} />

            <Route path="/logout" component={LogoutPage} />

            <Route path="/error" component={ErrorsPage} />

            <Redirect to="/profile-list" />

            {/* <Redirect to="/error/error-v1" /> */}
          </Switch>
        </Suspense>
      </Layout>}
      </>
    );
  };
  const renderGuestRoutes = () => {
    return (
      <Switch>
        <Route path="/logout" component={LogoutPage} />

        <Route path="/refresh-password/:id" component={RefreshPassword} />

        <Route path="/auth/login" component={Login} />
        <Route path="/auth/sign-up" component={Registration} />

        <Route
          path="/auth/create-trial-account"
          component={RegistrationTrial}
        />

        <Route path="/auth/forgot-password" component={ForgotPassword} />

        <Redirect to="/auth/login" />

        <Route path="/error" component={ErrorsPage} />

        <Redirect to="/auth/login" />
      </Switch>
    );
  };

  if (process.env.REACT_APP_ENV !== "development") {
    const tagManagerArgs = {
      gtmId: "GTM-T4FZT7",
    };

    TagManager.initialize(tagManagerArgs);
  }

  return (
    // <LayoutContextProvider history={history} menuConfig={menuConfig}>
    <LayoutLoader>
      {" "}
      {drift && userId && process.env.REACT_APP_DRIFT_CHAT !== "disable" && (
        <Drift
          appId="rzyz5e5by27m"
          userId={"USER-" + userEmail}
          attributes={drift}
          style={{
            bottom: "10px",
          }}
        />
      )}
      {intercom && process.env.REACT_APP_INTERCOM_CHAT !== "disable" && (
        <Intercom appId="hetr4i01" user={intercom} />
      )}
      {!!userId && (
        <Profitwell
          authToken="61437c71cb6da32d50cdf0c07719cdb7"
          email={`${userId}`}
        />
      )}
      {isAuthorized ? renderUserRoutes() : renderGuestRoutes()}
    </LayoutLoader>

    // </LayoutContextProvider>
  );
});

export default AppBuilder;
