import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import HeaderDropdownToggle from "../content/CustomDropdowns/HeaderDropdownToggle";
import "./UserProfile.scss";

const UserProfile = ({
  user,
  showHi,
  showBadge,
  crm_list,
  profileLimit,
  active_profiles,
}) => {
  const [role, setRole] = useState(user.roles);

  useEffect(() => {
    setRole(user.roles);
  }, [user.roles]);

  const crm = crm_list.find((elem) => elem.is_enabled);

  return (
    <Dropdown
      className="kt-header__topbar-item kt-header__topbar-item--user user-profile"
      drop="down"
      alignRight
    >
      <Dropdown.Toggle
        as={HeaderDropdownToggle}
        id="dropdown-toggle-user-profile"
      >
        <div className="kt-header__topbar-user">
          {showHi && (
            <span className="kt-header__topbar-welcome kt-hidden-mobile">
              Hi,
            </span>
          )}
          {showHi && (
            <span className="kt-header__topbar-username kt-hidden-mobile">
              {user.firstName} {user.lastName}
            </span>
          )}

          {false && showBadge && (
            <span className="kt-badge kt-badge--username kt-badge--unified-success kt-badge--lg kt-badge--rounded kt-badge--bold">
              John Doe
            </span>
          )}
        </div>
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl"
        style={{
          width: "260px",
          transform: "translate3d(-260px, 64px, 0px)",
        }}
      >
        <div className="kt-notification">
          <Dropdown.Item as={"div"} style={{ all: "unset" }}>
            <NavLink to="/account/info" className="kt-notification__item">
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  Account Info
                </div>
              </div>
            </NavLink>
          </Dropdown.Item>

          {(role === "super-admin" || role === "admin") && (
            <Dropdown.Item as={"div"} style={{ all: "unset" }}>
              <NavLink to="/account/setting" className="kt-notification__item">
                <div className="kt-notification__item-details">
                  <div className="kt-notification__item-title kt-font-bold">
                    Settings
                  </div>
                </div>
              </NavLink>
            </Dropdown.Item>
          )}

          {(role === "super-admin" || role === "admin") && crm && (
            <Dropdown.Item as={"div"} style={{ all: "unset" }}>
              <NavLink
                to="/account/crm/connect"
                className="kt-notification__item"
              >
                <div className="kt-notification__item-details">
                  <div className="kt-notification__item-title kt-font-bold">
                    CRM
                  </div>
                </div>
              </NavLink>
            </Dropdown.Item>
          )}

          {(role === "super-admin" || role === "admin") && (
            <Dropdown.Item as={"div"} style={{ all: "unset" }}>
              <NavLink to="/account/biling" className="kt-notification__item">
                <div className="kt-notification__item-details">
                  <div className="kt-notification__item-title kt-font-bold">
                    Billing
                  </div>
                </div>
              </NavLink>
            </Dropdown.Item>
          )}

          <Dropdown.Item as={"div"} style={{ all: "unset" }}>
            <NavLink to="/logout" className="kt-notification__item">
              <div className="kt-notification__item-details">
                <div className="kt-notification__item-title kt-font-bold">
                  Logout
                </div>
              </div>
            </NavLink>
          </Dropdown.Item>

          {(role === "super-admin" || role === "admin") && (
            <div className="profile-line">
              <div className="profile-line__title">Active Profile Limit</div>
              <div className="profile-line__elem">
                <div
                  className="profile-line__value"
                  style={{
                    width: `${(100 / profileLimit) * active_profiles}%`,
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

const mapStateToProps = ({ auth: { user }, user_info, appData }) => ({
  user,
  crm_list: user_info.CRM_list,
  plan: user_info.subscription?.plan?.name,
  profileLimit: user_info.subscription?.plan?.active_profiles || 1,
  active_profiles: appData.account.active_profiles,
});

export default connect(mapStateToProps)(UserProfile);
